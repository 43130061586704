import { Link } from '@mui/material';

const WhatsAppButton = () => {
  return (
    <>
      <Link
        href="https://api.whatsapp.com/send?phone=558574003278"
        className="whatsapp-button"
        target="_blank"
        style={{ position: 'fixed', right: 30, bottom: 30 }}>
        <img src="https://i.ibb.co/VgSspjY/whatsapp-button.png" alt="botão whatsapp" width={70} height={70} />
      </Link>
    </>
  );
};

export { WhatsAppButton };
